var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fangtan"},[_c('div',{staticClass:"mainWidth"},[_c('el-row',{staticClass:"crumbs",attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('el-breadcrumb',{attrs:{"separator":">"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/' }}},[_vm._v("社科视频首页")]),_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.fubiao))])],1)],1)],1),_c('div',{staticClass:"container"},[_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('ViceHeadline',{attrs:{"title":_vm.fubiao}}),_c('el-row',{attrs:{"gutter":_vm.$store.state.isEquipment == 'pc' ? 20 : 0}},_vm._l((_vm.list),function(item){return _c('el-col',{key:item.index,attrs:{"xs":24,"sm":6,"md":6,"lg":6,"xl":6}},[_c('router-link',{attrs:{"target":"_blank","to":{
                  path: '/xq',
                  query: {
                    qing: item.id,
                    mianfu: _vm.fubiao,
                  },
                }}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"coverWrap"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.pub_cover),expression:"item.pub_cover"}],staticClass:"cover",class:{ bookCover: _vm.fubiao === '好书' },attrs:{"alt":""}}),_c('div',{staticClass:"duration"},[_c('p',[_vm._v(_vm._s(item.TIME_LENGTH))])]),_c('img',{staticClass:"pause",attrs:{"src":require("@/assets/image/zixun/播放.png"),"alt":""}})]),_c('div',{staticClass:"card_content"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.SYS_TOPIC))]),_c('p',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.$ModuleDate(item.RECORD_TIME))+" ")])])])])],1)}),1),_c('Paging',{attrs:{"value":_vm.value},on:{"transfer":_vm.getData}})],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }